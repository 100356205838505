import './config/release'
import './plugins'
import router from './router'
import Vue from 'vue'
import store from './store'
import App from './App.vue'

store.commit('setToken', localStorage.getItem('token'))
router.beforeEach((to, from, next) => {
  let dispatchAccountInfo = false
  store.commit('setNeedAuth', to.meta && to.meta.auth ? true : false)
  store.commit('setNeedOAuth', to.meta && to.meta.oAuth ? true : false)
  if (localStorage.getItem('token') && !store.state.account) {
    dispatchAccountInfo = store.dispatch('AccountInfo')
  }
  if (to.meta.auth && !store.state.account) {
    if (!localStorage.getItem('token')) {
      next('/account/login')
      return
    }
    if (!dispatchAccountInfo) {
      dispatchAccountInfo = store.dispatch('AccountInfo')
    }
    dispatchAccountInfo.then(() => {
      next()
    }).catch(() => {
      next('/static/error')
    })
  }
  if (to.path.indexOf('/m/operation') == 0) {
    let dispatchOAccountInfo = false
    const operateId = to.params.id
    let oToken = localStorage.getItem('token-' + operateId);
    if(store.state.oAccount && store.state.oAccount.node_id != operateId){
      store.commit('setOAccount', false)
      store.commit('setCurrentNode', false)
    }
    store.commit('setOId', operateId)
    store.commit('setSelectedMenu', {'selected': ['operation-' + operateId], 'open': ['operation']})
    store.commit('setOToken', {id: operateId, token: oToken})
    if (oToken && !store.state.oAccount) {
      dispatchOAccountInfo = store.dispatch('OAccountInfo')
    }
    if (to.meta.oAuth && !store.state.oAccount) {
      if (!localStorage.getItem('token-' + operateId)) {
        next('/m/operation/' + operateId + '/login')
        return
      }
      if (!dispatchOAccountInfo) {
        dispatchOAccountInfo = store.dispatch('OAccountInfo')
      }
      dispatchOAccountInfo.then(() => {
        next()
      }).catch(() => {
        next('/static/error')
        return
      })
    }
  }else{
    document.title = '悦动网络';
  }
  next()
})

new Vue({
  el: '#guazi_video',
  router,
  store,
  render: h => h(App)
});
