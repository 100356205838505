<template>
  <div class="guazi_video" id="guazi_video">
    <router-view ref="container"/>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'guazi_video',
  }

</script>

<style lang="less">
  @import "./assets/style/basic";
</style>
