import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const AccountCreate = resolve => require(['../pages/account/create'], resolve)
const AccountLogin = resolve => require(['../pages/account/login'], resolve)
const MDashboard = resolve => require(['../pages/m/dashboard'], resolve)
const MAlbumIndex = resolve => require(['../pages/m/album/index'], resolve)
const MDistributionIndex = resolve => require(['../pages/m/distribution/index'], resolve)
const MDistributionDetail = resolve => require(['../pages/m/distribution/detail'], resolve)
const MAdminRoleIndex = resolve => require(['../pages/m/admin/role/index'], resolve)
const MAdminTaskIndex = resolve => require(['../pages/m/admin/task/index'], resolve)
const MAdminSeriesIndex = resolve => require(['../pages/m/admin/series/index'], resolve)
const MAdminRightsIndex = resolve => require(['../pages/m/admin/rights/index'], resolve)
const MAdminGroupIndex = resolve => require(['../pages/m/admin/group/index'], resolve)
const MAdminApplyIndex = resolve => require(['../pages/m/admin/apply/index'], resolve)
const MAdminConfigIndex = resolve => require(['../pages/m/admin/config/index'], resolve)
const MAdminSourceIndex = resolve => require(['../pages/m/admin/source/index'], resolve)
const MAdminNodeIndex = resolve => require(['../pages/m/admin/node/index'], resolve)
const MAdminCharacterIndex = resolve => require(['../pages/m/admin/character/index'], resolve)
const MAdminActivityIndex = resolve => require(['../pages/m/admin/activity/index'], resolve)
const MAdminActivityDetailIndex = resolve => require(['../pages/m/admin/activity/detail'], resolve)
const MAdminDocIndex = resolve => require(['../pages/m/admin/doc/index'], resolve)
const MAdminDocDetail = resolve => require(['../pages/m/admin/doc/detail'], resolve)
const MAdminRecruit = resolve => require(['../pages/m/admin/recruit/index'], resolve)
const MSourceIndex = resolve => require(['../pages/m/source/index'], resolve)
const MDocIndex = resolve => require(['../pages/m/doc/index'], resolve)
const MOperationIndex = resolve => require(['../pages/m/operation/index'], resolve)
const MOperationLogin = resolve => require(['../pages/m/operation/login'], resolve)
const MOperationAlbum = resolve => require(['../pages/m/operation/album/index'], resolve)
const MOperationCategory = resolve => require(['../pages/m/operation/category/index'], resolve)
const MOperationCharacter = resolve => require(['../pages/m/operation/character/index'], resolve)
const MOperationApprove = resolve => require(['../pages/m/operation/approve/index'], resolve)
const MOperationApprovelog = resolve => require(['../pages/m/operation/approve/log'], resolve)
const MOperationActivity = resolve => require(['../pages/m/operation/activity/index'], resolve)
const MOperationActivityConfig = resolve => require(['../pages/m/operation/activity/config/index'], resolve)
const MOperationActivityDetail = resolve => require(['../pages/m/operation/activity/detail'], resolve)
const MOperationActivityConfigDetail = resolve => require(['../pages/m/operation/activity/config/detail'], resolve)
const MOperationMaterial = resolve => require(['../pages/m/operation/material'], resolve)
const MOperationClassification = resolve => require(['../pages/m/operation/classification/index'], resolve)
const MOperationClassificationDetail = resolve => require(['../pages/m/operation/classification/detail'], resolve)
const MOperationKeyword = resolve => require(['../pages/m/operation/keyword/index'], resolve)
const MOperationBeian = resolve => require(['../pages/m/operation/beian/index'], resolve)
const MOperationFee = resolve => require(['../pages/m/operation/fee/index'], resolve)
const MOperationConfig = resolve => require(['../pages/m/operation/config/index'], resolve)
const MOperationDB = resolve => require(['../pages/m/operation/db/index'], resolve)
const MOperationTask = resolve => require(['../pages/m/operation/task/index'], resolve)
const MOperationStatTodayIndex = resolve => require(['../pages/m/operation/stat/today/index'], resolve)
const MOperationStatClickIndex = resolve => require(['../pages/m/operation/stat/click/index'], resolve)
const MOperationStatRankIndex = resolve => require(['../pages/m/operation/stat/rank/index'], resolve)
const MOperationStatUserIndex = resolve => require(['../pages/m/operation/stat/user/index'], resolve)
const MOperationDownload = resolve => require(['../pages/m/operation/download/index'], resolve)
const MOperationDownloadSetting = resolve => require(['../pages/m/operation/download/setting'], resolve)
const MOperationInjection = resolve => require(['../pages/m/operation/injection/index'], resolve)
const MOperationInjectionSetting = resolve => require(['../pages/m/operation/injection/setting'], resolve)
const MOperationServer = resolve => require(['../pages/m/operation/server/index'], resolve)
const MGroupIndex = resolve => require(['../pages/m/group/index'], resolve)
const MCharacterIndex = resolve => require(['../pages/m/character/index'], resolve)
const MNodeIndex = resolve => require(['../pages/m/node/index'], resolve)
const MNodeDetail = resolve => require(['../pages/m/node/detail'], resolve)
const MApplyIndex = resolve => require(['../pages/m/apply/index'], resolve)
const MCertificateIndex = resolve => require(['../pages/m/certificate/index'], resolve)
const HomeIndex = resolve => require(['../pages/index'], resolve)
const HomePartner = resolve => require(['../pages/partner'], resolve)
const UserCenter = resolve => require(['../pages/user/center'], resolve)


let path_base = [
  {
    path: '/',
    name: HomeIndex.name,
    component: HomeIndex
  },
  {
    path: '/partner',
    name: HomePartner.name,
    component: HomePartner
  },
  {
    path: '/account/create',
    name: AccountCreate.name,
    component: AccountCreate
  },
  {
    path: '/account/login',
    name: AccountLogin.name,
    component: AccountLogin
  },
  {
    path: '/user/center',
    name: UserCenter.name,
    component: UserCenter,
  },
  {
    path: '/m/doc/:key',
    name: MDocIndex.name,
    component: MDocIndex,
    meta: {auth: true}
  }
]

let path_enterprise = [
  {
    path: '/m',
    name: MDashboard.name,
    component: MDashboard,
    meta: {auth: true}
  },
  {
    path: '/m/cert/series',
    name: MAlbumIndex.name,
    component: MAlbumIndex
  },
  {
    path: '/m/cert/source',
    name: MSourceIndex.name,
    component: MSourceIndex,
    meta: {auth: true}
  },
  {
    path: '/m/cert/group',
    name: MGroupIndex.name,
    component: MGroupIndex,
    meta: {auth: true}
  },
  {
    path: '/m/cert/apply',
    name: MApplyIndex.name,
    component: MApplyIndex,
    meta: {auth: true}
  },
  {
    path: '/m/cert/file',
    name: MCertificateIndex.name,
    component: MCertificateIndex,
    meta: {auth: true}
  },
  {
    path: '/m/app/node',
    name: MNodeIndex.name,
    component: MNodeIndex,
    meta: {auth: true}
  },
  {
    path: '/m/app/node/:id',
    name: MNodeDetail.name,
    component: MNodeDetail,
    meta: {auth: true}
  },
  {
    path: '/m/app/series',
    name: MDistributionIndex.name,
    component: MDistributionIndex,
    meta: {auth: true}
  }
  ,
  {
    path: '/m/cert/character',
    name: MCharacterIndex.name,
    component: MCharacterIndex,
    meta: {auth: true}
  }
  ,
  {
    path: '/m/cert/distribution',
    name: MDistributionDetail.name,
    component: MDistributionDetail,
    meta: {auth: true}
  }
]

let path_operate = [
  {
    path: '/m/operation/:id',
    name: MOperationIndex.name,
    component: MOperationIndex,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/login',
    name: MOperationLogin.name,
    component: MOperationLogin
  },
  {
    path: '/m/operation/:id/series',
    name: MOperationAlbum.name,
    component: MOperationAlbum,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/series/category',
    name: MOperationCategory.name,
    component: MOperationCategory,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/approve',
    name: MOperationApprove.name,
    component: MOperationApprove,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/approve/log',
    name: MOperationApprovelog.name,
    component: MOperationApprovelog,
    meta: {oAuth:true}
  },
  {
    path: '/m/operation/:id/series/character',
    name: MOperationCharacter.name,
    component: MOperationCharacter,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/series/keyword',
    name: MOperationKeyword.name,
    component: MOperationKeyword,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/series/beian',
    name: MOperationBeian.name,
    component: MOperationBeian,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/classification',
    name: MOperationClassification.name,
    component: MOperationClassification,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/classification/:cid',
    name: MOperationClassificationDetail.name,
    component: MOperationClassificationDetail,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/activity/:aid',
    name: MOperationActivityDetail.name,
    component: MOperationActivityDetail,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/material',
    name: MOperationMaterial.name,
    component: MOperationMaterial,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/activity',
    name: MOperationActivity.name,
    component: MOperationActivity,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/setting/fee',
    name: MOperationFee.name,
    component: MOperationFee,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/setting/activity',
    name: MOperationActivityConfig.name,
    component: MOperationActivityConfig,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/setting/activity/:id',
    name: MOperationActivityConfigDetail.name,
    component: MOperationActivityConfigDetail,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/download',
    name: MOperationDownload.name,
    component: MOperationDownload,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/download/setting',
    name: MOperationDownloadSetting.name,
    component: MOperationDownloadSetting,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/injection',
    name: MOperationInjection.name,
    component: MOperationInjection,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/injection/setting',
    name: MOperationInjectionSetting.name,
    component: MOperationInjectionSetting,
    meta: {oAuth: true}
  },

  {
    path: '/m/operation/:id/setting/config',
    name: MOperationConfig.name,
    component: MOperationConfig,
    meta: {oAuth: true}
  },{
    path: '/m/operation/:id/setting/db',
    name: MOperationDB.name,
    component: MOperationDB,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/setting/task',
    name: MOperationTask.name,
    component: MOperationTask,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/setting/server',
    name: MOperationServer.name,
    component: MOperationServer,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/statistics/today',
    name: MOperationStatTodayIndex.name,
    component: MOperationStatTodayIndex,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/statistics/click',
    name: MOperationStatClickIndex.name,
    component: MOperationStatClickIndex,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/statistics/rank',
    name: MOperationStatRankIndex.name,
    component: MOperationStatRankIndex,
    meta: {oAuth: true}
  },
  {
    path: '/m/operation/:id/statistics/user',
    name: MOperationStatUserIndex.name,
    component: MOperationStatUserIndex,
    meta: {oAuth: true}
  }
]

let path_admin = [
  {
    path: '/m/admin/setting/role',
    name: MAdminRoleIndex.name,
    component: MAdminRoleIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/setting/task',
    name: MAdminTaskIndex.name,
    component: MAdminTaskIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/series',
    name: MAdminSeriesIndex.name,
    component: MAdminSeriesIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/series/rights',
    name: MAdminRightsIndex.name,
    component: MAdminRightsIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/series/group',
    name: MAdminGroupIndex.name,
    component: MAdminGroupIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/series/character',
    name: MAdminCharacterIndex.name,
    component: MAdminCharacterIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/series/activity',
    name: MAdminActivityIndex.name,
    component: MAdminActivityIndex,
    meta: {auth: true}
  }
  ,{
    path: '/m/admin/series/activity/:id',
    name: MAdminActivityDetailIndex.name,
    component: MAdminActivityDetailIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/approve/apply',
    name: MAdminApplyIndex.name,
    component: MAdminApplyIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/approve/source',
    name: MAdminSourceIndex.name,
    component: MAdminSourceIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/approve/node',
    name: MAdminNodeIndex.name,
    component: MAdminNodeIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/setting/doc',
    name: MAdminDocIndex.name,
    component: MAdminDocIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/setting/config',
    name: MAdminConfigIndex.name,
    component: MAdminConfigIndex,
    meta: {auth: true}
  },
  {
    path: '/m/admin/setting/recruit',
    name: MAdminRecruit.name,
    component: MAdminRecruit,
    meta: {auth: true}
  },
  {
    path: '/m/admin/setting/doc/:id',
    name: MAdminDocDetail.name,
    component: MAdminDocDetail,
    meta: {auth: true}
  }
]

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...path_base,
    ...path_enterprise,
    ...path_admin,
    ...path_operate
  ]
})

export default router;
